import React, { useState } from 'react';
import './VexoftPlaceholder.css'
import { ReactComponent as Logo } from './logo.svg';
import { Button, Form } from 'react-bootstrap';
import { useListPetsQuery, useLazyListPetsQuery } from '../generated/store/petApi'

const VexoftPlaceholder = () => {
    const [query, setQuery] = useState('');

    // const { data } = useListPetsQuery({ limit: 3 })
    const [getPets, { data: pets }] = useLazyListPetsQuery()

    const handleSearch = () => {
        if (query) {
            alert(`You typed: '${query}'. Congrats! :)`);
        }
    };

    return (
        <>
            <div className="container">
                <Logo style={{ marginBottom: "15px" }} />
                <div className="search-container">
                    <input
                        type="text"
                        className="search-bar"
                        placeholder="Search..."
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                    />
                    <button className="search-btn" onClick={handleSearch}>Search</button>
                </div>
                <Button onClick={e => getPets({limit: 3})}>Click me</Button>
                <Form.Group>
                    <Form.Label>Cucubau</Form.Label>
                    <Form.Control isValid={false}></Form.Control>
                </Form.Group>
            </div>
            <div>
            {pets?.map(e => <div>{`${e.id}-${e.name}`}</div>)}
            </div>
        </>

    );
};

export default VexoftPlaceholder;
