import './App.css';
import './custom-style.scss'

import VexoftPlaceholder from './components/VexoftPlaceholder';

function App() {
  return (
    <VexoftPlaceholder />
  );
}

export default App;
